<template>
  <div class>
    <v-row no-gutters>
      <SubBar
        title="Historique de mes licences"
        route_name="mes_licences__ma_licence"
      />
    </v-row>
    <v-row>
      <v-col
        class="px-6"
        v-if="currentUser && currentStructure && currentSaison"
      >
        <a
          v-if="
            licenceHorsClub &&
              licenceHorsClub.ID_Saison + 1 === currentSaison.Annee &&
              $moment().isAfter(currentSaison.DateDebutSaison)
          "
          @click="showRenouvellementModal"
          >Renouveler ma licence hors club</a
        >
        <RenouvellementModal
          v-if="licenceHorsClub"
          :isModalOpen="isModalOpen"
          @onClose="closeRenouvellementModal"
          :user="licenceHorsClub.UTI_Utilisateur"
        ></RenouvellementModal>
        <HistoriqueLicence
          :context="{
            ID_Utilisateur: currentUser.id,
            ID_Saison: currentSaison.id,
            ID_Structure: currentStructure.ID_Structure,
            all: true,
          }"
          :withTitle="false"
        />
      </v-col>
      <v-col class="px-6" v-else>
        <p>Votre historique de licence est vide</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import HistoriqueLicence from "@/components/Assurances/Assurances__Historique.vue";
import RenouvellementModal from "@/modules/MesLicences/RenouvellementModal.vue";
import { mapGetters, mapActions } from "vuex";
import SubBar from "@/components/Common/Common__SubBar.vue";

export default {
  components: { HistoriqueLicence, SubBar, RenouvellementModal },

  data: () => ({
    licences: [],
    licenceHorsClub: null,
    isModalOpen: false,
  }),

  computed: {
    ...mapGetters("user", ["currentUser"]),
    ...mapGetters("saisons", ["selectedSaison", "currentSaison"]),
    ...mapGetters("structure", ["currentStructure"]),
  },
  async created() {
    this.licenceHorsClub = (
      await this.getUserHorsClubLicence({
        ID_Utilisateur: this.currentUser.id,
      })
    )[0];
    if (!this.currentStructure) await this.setCurrentStructureFederal();
    console.log("licenceHorsClub:", this.licenceHorsClub);
    console.log("currentStructure:", this.currentStructure);
  },
  methods: {
    ...mapActions("user", ["getUserHorsClubLicence"]),
    ...mapActions("auth", ["initRolesAndPermissions"]),
    ...mapActions("structure", ["setCurrentStructureFederal"]),
    showRenouvellementModal() {
      this.isModalOpen = true;
    },
    closeRenouvellementModal() {
      this.isModalOpen = false;
    },
  },
};
</script>
