<template>
  <div>
    <v-row no-gutters>
      <SubBar
        :title="subBarTitle"
        :showSubBar="false"
        :isLicence="false"
        :saison="currentSaison.NomSaison"
      />
    </v-row>
    <div v-if="!loader" class="ma_licence__loading-container">
      <Loading :loading="true" />
    </div>
    <v-row no-gutters style="height: calc(100vh - 145px); overflow-y: scroll">
      <v-col cols="12" lg="10" style="margin: 0 auto">
        <v-row v-if="mounted">
          <v-col cols="6" v-if="userHasLicence">
            <!-- <QRCodeLicence
              :context="{ ID_Utilisateur: currentUserId }"
            ></QRCodeLicence> -->
            <v-row style="margin-top: 20px;" v-if="licenceNeedDoc">
              <v-alert text type="info">
                <p>Pour activer la licence, la validation du questionnaire santé est nécessaire. Seuls les licenciés suivants devront présenter un certificat médical tous les 3 ans (et attestation du questionnaire santé dans l'intervalle des 3 ans) :</p>
                <ul style="list-style: disc;">
                  <li>Les licenciés (jeunes ou adultes) ayant répondu "oui" à l'une des questions du questionnaire santé</li>
                  <li>Les compétiteurs adultes escalade à partir des 1/2 Finales Championnat France et Coupe de France</li>
                  <li>Les compétiteurs adultes en ski-alpinisme</li>
                </ul>
              </v-alert>
            </v-row>

            <v-row style="margin-top: 20px;">
              <v-col cols="12" v-if="!isLicenceDecouverte">
                <v-tooltip bottom :disabled="!isPrintDisabled.disabled">
                  <template v-slot:activator="{ on }">
                    <v-btn
                      :href="printLicenceLinkDouble"
                      :disabled="isPrintingLicence || isPrintDisabled.disabled"
                      :loading="isPrintingLicence"
                      class="btn btn-primary mt-2"
                      v-on="on"
                    >
                      Imprimer ma licence
                    </v-btn>
                  </template>
                  Téléchargement impossible, vos données de santé ne sont pas à jour.
                </v-tooltip>
              </v-col>
              <v-col cols="12" v-if="!isLicenceDecouverte">
                <v-btn
                  class="btn btn-primary"
                  :disabled="
                    isPrintingAttestationLicence || isPrintDisabled.disabled
                  "
                  :loading="isPrintingAttestationLicence"
                  @click="
                    printAttestation(
                      `attestation-${currentUser.CT_Prenom}-${currentUser.CT_Nom}-saison${currentSaison.id}`
                    )
                  "
                  >Imprimer mon attestation de licence</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-btn
                  class="btn btn-primary"
                  :to="{ name: 'mes_licences__historique_des_licences' }"
                  >Historique de mes licences</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else cols="6" class="mt-4">
            <v-alert
              border="top"
              colored-border
              color="orange lighten-5"
              icon="info"
              elevation="2"
            >
              Aucune licence pour cette saison.
            </v-alert>

            <v-col cols="12" v-if="lastLicenceProduct && lastLicenceProduct.slug.includes('hors_club') && lastLicenceSeason !== currentSaison.id">
              <v-btn
                class="btn btn-primary"
                @click="renewLicence"
              >Renouveler ma licence</v-btn>
            </v-col>

            <v-col cols="12">
              <v-btn
                class="btn btn-primary"
                :to="{ name: 'mes_licences__historique_des_licences' }"
                >Historique de mes licences</v-btn
              >
            </v-col>
          </v-col>
          <v-col cols="6">
            <FicheLicence
              :context="{ ID_Utilisateur: currentUserId }"
            ></FicheLicence>
          </v-col>
        </v-row>
      </v-col>
      <PrintLicence ref="licence" :print="print"></PrintLicence>
      <PrintAttestation ref="attestation" :print="print"></PrintAttestation>
    </v-row>
  </div>
</template>

<script>
import SubBar from "@/components/Common/Common__SubBar.vue";
import QRCodeLicence from "@/components/User/User__QRCode.vue";
import FicheLicence from "@/components/User/User__FicheLicence.vue";
import PrintLicence from "@/modules/MesLicences/PrintLicence.vue";
import PrintAttestation from "@/modules/MesLicences/PrintAttestation.vue";
import { mapGetters, mapActions, mapState } from "vuex";
import Loading from "@/components/LoadingIndicator.vue";
import { waitingDocument, loisir, competition, qs } from "@/constants/licenceStatus";
import {OPTION_TYPE_ASSURANCE, OPTION_TYPE_IJ, OPTION_TYPE_OPTION_ASSURANCE, OPTION_TYPE_OPTION_LICENCE_HC} from "@/plugins/productService";

export default {
  components: {
    SubBar,
    // QRCodeLicence,
    FicheLicence,
    PrintLicence,
    PrintAttestation,
    Loading,
  },

  data: () => ({
    licencesUser: null,
    mounted: false,
    print: {
      assurance: null,
      cm: null,
      user: null,
      licence: null,
      complementaire: null,
      options: [],
      ID_Saison: null,
      template: null,
    },
    loader: false,
    lastLicenceSeason: null,
    lastLicenceProduct: null,
  }),

  async created() {
    console.log("ma licence :>> ");
    const lastLicence = await this.lastLicence();

    if (lastLicence) {
      this.lastLicenceProduct = lastLicence.product;
    }

    await this.setData();
    // if (this.currentStructure) {
    this.print.ID_Saison = this.currentSaison.id;
    this.print.licence = this.licencesUser[0] ?? []

    if (this.print.licence) {
      this.setPrintLicenceInfo();
    }
    // }

    this.mounted = true;
    this.loader = true;
    return;
  },

  computed: {
    ...mapGetters("user", [
      "currentUserId",
      "currentUser",
      "getUserFull",
      "currentUserAvatar",
    ]),
    ...mapGetters("saisons", ["currentSaison", "SelectedSaison"]),
    ...mapGetters("structure", ["currentStructure", "currentStructureId"]),
    ...mapState({
      utilisateurFullData: (state) => state.utilisateurs.utilisateursFullData,
      userFullData: (state) => state.utilisateurs.UTI_Utilisateur,
      isPrintingLicence: (state) => state.licences.isPrintingLicence,
      isPrintingAttestationLicence: (state) =>
        state.licences.isPrintingAttestationLicence,
    }),

    printLicenceLinkDouble() {
      return `${process.env.VUE_APP_FFME_BACK_URL}/api/licences/${this.print.licence.id}/download/double`;
    },

    isPrintDisabled() {
      if (this.print.cm === waitingDocument) {
        return { disabled: true, message: "Pas de document médical valide" };
      }
      if (!this.print.licence)
        return { disabled: true, message: "Pas de licence valide" };
      // disable print if no licence active on currentSaison
      if (!this.print.licence.dateEnd)
        return {
          disabled: true,
          message: "Pas de fin de validité de la licence",
        };
      if (!this.print.licence.dateStart)
        return {
          disabled: true,
          message: "Pas de date de validation de la licence",
        };
      return { disabled: false };
    },
    subBarTitle() {
      return "Ma licence " + this.currentSaison.NomSaison;
    },

    userHasLicence() {
      return !(!this.licencesUser || this.licencesUser.length === 0);
    },

    isLicenceDecouverte() {
      const licence = this.licencesUser[0];

      return licence && licence.product.slug === "licence_decouverte";
    },

    licenceNeedDoc() {
      if (!this.licencesUser) {
        return true;
      }

      const licence = this.licencesUser[0];

      if (!licence) {
        return false;
      }

      if (this.isLicenceDecouverte) {
        return false;
      }

      if (new Date(licence.dateEnd) < new Date()) {
        return false;
      }

      return licence.status === waitingDocument;
    },
  },
  methods: {
    ...mapActions("utilisateurs", [
      "getLicenceUtilisateur",
      "getUtilisateurFullData",
      "getUserLicences"
    ]),
    ...mapActions("structure", ["setCurrentStructureFederal"]),
    ...mapActions("app", ["setAppTitle"]),
    ...mapActions("basics", [
      "getTemplatesBySlug",
    ]),

    ...mapActions("user", ["getCertificatsMedicaux", "getInfoSante"]),

    printLicence(name) {
      if (this.loader) {
        this.$store.commit("licences/SET_IS_PRINTING_LICENCE", true);
        this.$refs &&
          this.$refs.licence &&
          this.$refs.licence.printLicence(name);
      }
    },
    printAttestation(name) {
      this.$store.commit("licences/SET_IS_PRINTING_ATTESTATION_LICENCE", true);
      this.$refs &&
        this.$refs.attestation &&
        this.$refs.attestation.printAttestation(name);
    },

    async setPrintLicenceInfo() {
      this.print.options = this.print.licence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_OPTION_ASSURANCE || opt.option.optionType.slug === OPTION_TYPE_OPTION_LICENCE_HC);
      this.print.complementaire = this.print.licence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_IJ);
      this.print.assurance = this.print.licence.licenceOptions.filter(opt => opt.option.optionType.slug === OPTION_TYPE_ASSURANCE )[0] ?? null;
      this.print.cm = [waitingDocument, loisir, competition, qs].includes(this.print.licence.status) ? this.print.licence.status : await this.getInfoSante({ ID_Utilisateur: this.currentUserId });
      this.print.user = { ...this.getUserFull };
      let payload = {
        ID_Saison: this.print.ID_Saison,
        SlugNomTemplate: "Licence_template",
      };
      this.print.template = await this.getTemplatesBySlug(payload);
      this.loader = true;
    },

    async setData() {
      await this.setAppTitle({ title: "Ma licence" });

      this.licencesUser = await this.getLicenceUtilisateur({
        user: this.currentUserId,
        season: this.currentSaison.id,
        'groups[1]': 'licence_history:read',
        'order[dateEnd]': 'desc',
      });
    },

    async lastLicence() {
      // Ici on récupère tous les licences même celles qui sont inactives (annulées)
      // pour l'affichage du bouton renouveler pour les licenciés hors club
      const response = await this.getUserLicences({
        user: this.currentUserId,
        'order[season]': 'desc',
        'order[dateEnd]': 'desc',
      })

      if (response.length === 0) {
        return null;
      }

      const lastLicence = response[0];
      this.lastLicenceSeason = lastLicence.season.id;

      return lastLicence;
    },

    async renewLicence() {
      const lastLicence = await this.lastLicence();

      window.location = `${process.env.VUE_APP_FRONT_NEXT_URL}/gestion-des-licences/renouvellement/${lastLicence.id}?structureId=${lastLicence.structure.id}&appMode=${localStorage.getItem("ffme_user_mode")}`;
    },
  },
};
</script>

<style lang="scss"></style>
