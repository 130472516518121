<template>
  <v-row
    no-gutters
    class="gestion_des_licences__dashboard-layout"
    :class="{ loading: loading }"
  >
    <v-col cols="12" lg="8" class="mx-auto">
      <v-row style="height: 100%;">
        <v-col cols="12" class="p-4 licences-adherents-content">
          <v-row style="height: 100%;">
            <v-col cols="6">
              <card
                module_name="mes_licences__historique_des_licences"
                title_card="Historique de mes licences"
                :ID_User="currentUserId"
                :auth="false"
              />
            </v-col>
            <v-col cols="6">
              <card
                module_name="mes_licences__ma_licence"
                :title_card="
                  `Qrcode licence ${currentSaison.id - 1}-${currentSaison.id}`
                "
                :ID_User="currentUserId"
                :auth="false"
              />
            </v-col>

            <v-col cols="6"> </v-col>

            <v-col cols="6"> </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import card from "@/components/Common/DashboardCard.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "GestionDesLicences__Dashboard",

  components: { card },

  data: () => ({
    loading: true,
  }),
  async created() {
    await this.setAppTitle({ title: "Ma licence" });
    this.loading = false;
  },

  methods: {
    ...mapActions("app", ["setAppTitle"]),
  },

  computed: {
    ...mapGetters("user", ["currentUserId"]),
    ...mapGetters("saisons", ["currentSaison"]),
  },
};
</script>

<style lang="scss">
.gestion_des_licences__dashboard-layout {
  position: relative;
  height: calc(100vh - 80px);
  &.loading {
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
    }
  }
}
</style>
