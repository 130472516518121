<template>
  <div class="license-qrcode" v-if="mounted">
    <span>Ce QR code vous permet d’avoir un accès rapide à votre licence.</span>
    <a :href="url" target="_blank">
      <VueQrcode
        :value="url"
        :options="{
          width: 400,
          margin: 1,
        }"
        tag="img"
      >
      </VueQrcode>
    </a>
  </div>
  <div v-else>Chargement ...</div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";
export default {
  components: { VueQrcode },

  data: () => ({
    url: "#",
    mounted: false,
  }),

  created() {
    this.url =
      window.location.origin +
      this.$router.resolve("/licence/" + this.context.ID_Utilisateur).href;
    this.mounted = true;
  },

  props: {
    context: {
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.license-qrcode {
  background: #00000078;
  display: inline-block;
  padding: 40px 60px 60px;
  border-radius: 27px;
  width: auto;
  margin: 0 auto;
  color: white;
  max-width: 520px;
  text-align: center;

  img {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
