var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('v-row',{attrs:{"no-gutters":""}},[_c('SubBar',{attrs:{"title":"Historique de mes licences","route_name":"mes_licences__ma_licence"}})],1),_c('v-row',[(_vm.currentUser && _vm.currentStructure && _vm.currentSaison)?_c('v-col',{staticClass:"px-6"},[(
          _vm.licenceHorsClub &&
            _vm.licenceHorsClub.ID_Saison + 1 === _vm.currentSaison.Annee &&
            _vm.$moment().isAfter(_vm.currentSaison.DateDebutSaison)
        )?_c('a',{on:{"click":_vm.showRenouvellementModal}},[_vm._v("Renouveler ma licence hors club")]):_vm._e(),(_vm.licenceHorsClub)?_c('RenouvellementModal',{attrs:{"isModalOpen":_vm.isModalOpen,"user":_vm.licenceHorsClub.UTI_Utilisateur},on:{"onClose":_vm.closeRenouvellementModal}}):_vm._e(),_c('HistoriqueLicence',{attrs:{"context":{
          ID_Utilisateur: _vm.currentUser.id,
          ID_Saison: _vm.currentSaison.id,
          ID_Structure: _vm.currentStructure.ID_Structure,
          all: true,
        },"withTitle":false}})],1):_c('v-col',{staticClass:"px-6"},[_c('p',[_vm._v("Votre historique de licence est vide")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }