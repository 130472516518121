<template>
  <div id="print-licence" ref="print-licence">
    <div class="entete">
      <img class="habillage" src="~@/assets/FFME_EN_TETE.jpg" alt="" />
      <div class="content">
        <h4 class="text-center">Licence ffme</h4>
        <h4 class="text-center">
          Saison {{ print.ID_Saison - 1 }}-{{ print.ID_Saison }}
        </h4>
        <div class="print-img">
          <div class="user-infos" v-if="print.user">
            <div class="infos">
              <p>
                {{ print.user.CT_Prenom }}
                <span class="font-weight-bold text-uppercase">
                  {{ print.user.CT_Nom }}
                </span>
              </p>
              <p>
                Né le :
                {{ $moment(print.user.DN_DateNaissance).format("DD/MM/YYYY") }}
                - n°{{ print.user.LicenceNumero }}
              </p>
              <p class="small-text">
                Club :
                {{
                  print.licence &&
                  print.licence.structure &&
                  (
                    (print.licence.structure.idTypeStructure === 5 || print.licence.structure.idTypeStructure === 4) && print.licence.product.slug.includes('hors_club') ?
                    'Licence HORS CLUB' :
                    print.licence.structure.nomStructure
                  )
                }}
              </p>
              <p class="small-text">
                Nationalité :
                {{
                  print.user.ADR_Pays ? print.user.ADR_Pays.NomNationalite : ""
                }}
              </p>
              <p class="small-text" v-if="print.assurance && print.licence">
                {{ print.licence.product.label }}
                -
                {{ print.assurance.option.label }}
              </p>
              <p class="small-text" v-if="print.options.length">
                Option(s) :
                <span
                  v-for="(assuranceOpt, i) in print.options"
                  :key="assuranceOpt.option.label"
                >
                  {{ assuranceOpt.option.label }}
                  <span v-if="i !== print.options.length - 1">-</span>
                </span>

                <span
                  v-for="(ij) in print.complementaire"
                  :key="ij.option.label"
                >
                  <span v-if="print.options.length > 0">-</span>
                  {{ ij.option.label }}
                </span>
              </p>
              <p class="small-text" v-if="print.cm">
                Certificat médical : {{ certifatsMedicaux }}
              </p>
              <p class="small-text" v-if="print.licence">
                Fin de validité de la licence :
                {{
                  $moment(print.licence.dateEnd).format(
                    "DD/MM/YYYY"
                  )
                }}
              </p>
            </div>
            <div class="avatar-qr">
              <div class="avatar">
                <img
                  id="avatar"
                  :src="avatarUrl"
                  crossOrigin="“anonymous”"
                  alt="user_avatar"
                />
              </div>
              <div class="qr">
                <p>Licence détaillée</p>
                <div>
                  <VueQrcode
                    :value="qrUrl"
                    :options="{
                      width: 120,
                      margin: 1,
                    }"
                    tag="img"
                  ></VueQrcode>
                </div>
              </div>
            </div>
          </div>
          <!-- <h1 class="season_horizonal">
            Saison {{ print.ID_Saison - 1 }}-{{ print.ID_Saison }}
          </h1> -->
          <img crossOrigin="“anonymous”" :src="visuelImpressionLicence" alt="licence ffme" :key="visuelImpressionLicence" />
        </div>
        <p>Imprimé le {{ $moment().format("DD/MM/YYYY") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueQrcode from "@chenfengyuan/vue-qrcode";

import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { mapGetters } from "vuex";
import { waitingDocument, loisir, competition, qs } from "@/constants/licenceStatus";
export default {
  components: { VueQrcode },
  data: () => ({
    printDisabled: false,
  }),
  props: {
    print: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters("structure", ["currentStructure"]),
    ...mapGetters("saisons", ["currentSaison"]),

    avatarUrl() {
      const avatar = `${
        this.print.user?.DOC_Documents &&
        this.print.user?.DOC_Documents[0]?.CheminDocument
      }?cacheblock=true`;
      // only on dev mode
      // const avatar = require(`@/assets/images/licenceFFME/default_avatar.jpg`);
      return avatar;
    },
    qrUrl() {
      return (
        window.location.origin +
        this.$router.resolve("/licence/" + this.print.user.id).href
      );
    },

    visuelImpressionLicence() {
      return this.print.template && this.print.template [0] && this.print.template [0].CheminDocument
    },

    /**
     * CM peut prendre les valeurs suivantes :
     * Loisir (3 ans)
     * Competition (3 ans)
     * Prescription Sport-Santé (à la main de l'adhérent)
     * Loisir (3 ans) - Alpinisme (1 an)
     * Competition (3 ans) - Alpinisme (1 an)
     *
     * Attention
     * 1) La combinaison “Loisir + Compétition” n’est pas possible (soit l’un, soit l’autre).
     * Si ce cas de figure doit se produire suite à un bug,n’afficher que le CM compétition.
     * 2)  La combinaison “Alpinisme” n’est pas possible. le CM Alpinisme est obligatoirement combiné avec un CM Loisir ou CM Compétition.
     * Si ce cas de figure doit se produire (CM Alpinise uniquement), n’afficher que le CM Alpinisme.
     * 3) La notion de QS est affichée dans l’historique des documents de santé d’un adhérent, mais cette notion
     * n’apparait pas sur la licence au format PDF.
     *
     */

    optionsDoc() {
        // Crée un Set pour stocker les options uniques
        const uniqueOptions = new Set();
        // Boucle sur les options pour les ajouter au Set
        this.print.options.forEach(option => {
          uniqueOptions.add(option.ASSU_AssuranceType.NomTypeAssurance);
        });
        // Convertit le Set en tableau et retourne une chaîne de caractères avec les options séparées par "-"
        return Array.from(uniqueOptions).join("-");
    },


    certifatsMedicaux() {
      if (![waitingDocument, loisir, competition, qs].includes(this.print.cm)) {
        return this.oldCertificatsMedicaux;
      }

      switch (this.print.cm) {
        case qs:
          return 'Questionnaire de santé';
        case loisir:
          return 'Loisir';
        case competition:
          return 'Compétition';
        default:
          return '';
      }
    },
    oldCertificatsMedicaux() {
      if (!this.print?.cm?.length) {
        return '-';
      }

      let certificats = '';

      const certificatMedicalTemp = this.print.cm.filter((cm) =>
        cm.LIST_DocumentType?.SlugTypeDocument.includes('certificat_medical')
      );

      const certificatMedical = certificatMedicalTemp.map((d) => {
        if (d.LIST_DocumentType.SlugTypeDocument === 'certificat_medical_competition') {
          const newLIST_DocumentType = {
            ...d.LIST_DocumentType,
            SlugTypeDocument: 'certificat_medical_compétition',
          };

          return {
            ...d,
            LIST_DocumentType: newLIST_DocumentType
          };
        }
        return d;
      });

      certificatMedical.forEach((element, i) => {
        if (element?.LIST_DocumentType?.SlugTypeDocument == 'certificat_medical_sport_sante') {
          certificats += 'Prescription sport-santé';
        } else {
          certificats += element?.LIST_DocumentType?.SlugTypeDocument.split('certificat_medical_').join('').split('_').join('-');
        }

        if (i !== certificatMedical.length - 1) {
          certificats += ' et ';
        }
      });

      if (certificats === 'Sport-sante') {
        certificats = 'Prescription sport-santé';
      }

      return certificats;
    },
  },
  methods: {
    printLicence(name) {
      this.$refs["print-licence"].style.display = "block";
      let source = document.querySelector("#print-licence");
      html2canvas(source, {
        letterRendering: 1,
        scale: 2,
        logging: true,
        useCORS: true,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/jpeg");
        const pdf = new jsPDF({
          orientation: "portrait",
        });
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
        pdf.save(name);
        this.$store.commit("licences/SET_IS_PRINTING_LICENCE", false);
        this.$refs["print-licence"].style.display = "none";
      });
    },
  },
};
</script>
<style lang="scss">
#print-licence {
  width: 1000px;
  position: absolute;
  z-index: -3;
  top: 0;
  left: 0;
  display: none;
  .entete {
    position: relative;
    width: 100%;
    height: 100%;
  }
  .habillage {
    width: 100%;
  }
  .content {
    width: 100%;
    position: absolute;
    top: 30%;
    // left: calc(50% - 600px);
  }
  .print-img {
    margin: 50px 0;
    position: relative;
    img {
      width: 100%;
    }
    .user-infos {
      display: flex;
      position: absolute;
      top: 85px;
      height: 320px;
      right: 0;
      width: 50%;
      padding: 0 0.5em;
      .infos {
        width: calc(100% - 150px);
        p {
          &.small-text {
            font-size: 0.85em;
          }
          margin-bottom: 0;
        }
      }
      .avatar-qr {
        width: 160px;
        .avatar {
          width: 150px;
          position: absolute;
          right: 0.5em;
        }
        .qr {
          width: 150px;
          position: absolute;
          bottom: 0;
          right: 0.5em;
          p {
            font-size: 0.8em;
            margin: 0;
          }
        }
      }
    }
    .season_horizonal {
      position: absolute;
      top: 220px;
      left: -60px;
      color: #0aa0e2;
      text-transform: uppercase;
      transform: rotate(-90deg);
      font-size: 36px;
      display: flex;
      justify-content: flex-start;
      font-weight: bold;
    }
  }
}
</style>
