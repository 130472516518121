<template>
  <v-dialog v-if="isModalOpen" v-model="isModalOpen" @click:outside="closeModal" max-width="1000px" style="background: #fff !important;">
    <div class="modal__content">
      <HorsClubInscription :user="user"></HorsClubInscription>
    </div>
  </v-dialog>
</template>

<script>
import HorsClubInscription from "@/modules/HorsClub/hors_club__inscription.vue"
export default {
  components: { HorsClubInscription },
  props: {
    isModalOpen: {
      type: Boolean,
      default: false
    },
    user: {
      type: Object
    }
  },
  methods: {
    closeModal() {
      console.log("clooose");
      this.$emit("onClose");
    }
  }
}
</script>

<style lang="scss">
.modal__content {
  padding: 2em;
  background: white;
}
</style>